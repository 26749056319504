<template>
  <div>
    <base-content-management 
        title="Image" 
        contentType="join_now" 
        :enableAdd="true" 
        :enableDelete="true" 
        :useCover="true"
        :useContentCta="true"
    >
    </base-content-management>
  </div>
</template>

<script>
import BaseContentManagement from '../content/BaseContentManagement.vue'

export default {
  title () {
    return `Join Now Images Management`
  },
  components: {
    BaseContentManagement,
  },
}
</script>

<style>

</style>
